"use client";
import { IBook } from "@/src/lib/book/types";
import { type FC } from "react";
import dynamic from "next/dynamic";
import { IHashtag } from "@/src/lib/hashtag/types";
import Link from "next/link";
import Image from "next/image";
import { BookIndicator } from "../common";

const Slider = dynamic(
  () => import("./HeaderSlider").then((mod) => mod.HeaderSlider),
  {
    ssr: false,
  }
);

type Props = {
  book: IBook;
  lng: string;
  storyView?: boolean;
};

export const BookPageHeader: FC<Props> = ({ book, lng, storyView = false }) => {
  let hashtags: IHashtag[] = [];

  if (!storyView) {
    if (book.hashtagStrong?.length) {
      hashtags = [...hashtags, ...book.hashtagStrong];
    }
    if (book.hashtagMedium?.length) {
      hashtags = [...hashtags, ...book.hashtagMedium];
    }
    if (book.hashtagRarely?.length) {
      hashtags = [...hashtags, ...book.hashtagRarely];
    }

    hashtags = Array.from(
      new Map(
        hashtags.map((hashtag) => [JSON.stringify(hashtag), hashtag])
      ).values()
    );
  }

  return (
    <div
      className="px-2 min-sb-height w-full"
      style={{ backgroundColor: book.backgroundColor }}
    >
      <div className="sb-height relative flex">
        {storyView && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-end pr-5 z-20">
            <BookIndicator lng={lng} />
          </div>
        )}
        <div className="absolute top-0 right-0 left-0 pt-6 pb-4 flex flex-col justify-center items-center text-left">
          <h1
            className="text-3xl font-semibold"
            style={{ color: book.textColor }}
          >
            {book.title}
          </h1>
          {book.authors?.length >= 1 && (
            <h2
              className="font-light text-xl"
              style={{ color: book.textColor }}
            >
              {book.authors[0].name}
            </h2>
          )}
        </div>
        {storyView ? (
          <div className="py-4 flex-1 flex w-full items-center">
            <div className="relative py-6 flex mx-auto items-center justify-center w-64 h-full">
              {!!book.mainImage && (
                <Image
                  alt={book.mainImage[0].alternativeText}
                  src={book.mainImage[0].url}
                  fill
                  sizes="(max-width: 768px) 100vw, 50vw"
                  className="object-contain"
                />
              )}
            </div>
          </div>
        ) : (
          <div className="lg:px-20 py-4 flex-1 flex w-full items-center">
            {!!book.mainImage && (
              <Slider sliderItems={book.mainImage} name="shared.slider" />
            )}
          </div>
        )}
      </div>
      {!storyView && (
        <>
          {book.description && (
            <div
              className="px-2 py-4 animate-appear"
              dangerouslySetInnerHTML={{ __html: book.description }}
              style={{ color: book.textColor }}
            ></div>
          )}
          {!!hashtags.length && (
            <div
              className="px-2 pt-4 py-8 flex gap-1 animate-appear"
              style={{ color: book.textColor }}
            >
              {hashtags.map((hashtag) => (
                <Link
                  href={`/${lng}/hashtag/${hashtag.hashtagSlug}`}
                  key={hashtag.id}
                >
                  #{hashtag.name}
                </Link>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
