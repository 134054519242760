"use client";
import { IBook } from "@/src/lib";
import clsx from "clsx";
import Image from "next/image";
import { BookTitle } from "./BookTitle";
import dynamic from "next/dynamic";
import type { FC, ReactNode } from "react";
import Link from "next/link";

const FavoriteButton = dynamic(
  () => import("./FavoriteButton").then((m) => m.FavoriteButton),
  { ssr: false }
);

interface Props {
  book: IBook;
  search?: string;
  isSearch?: boolean;
  bookOrientation?: "vertical" | "horizontal";
  hasFavoriteButton?: boolean;
  lng: string;
}

const Book = ({
  book,
  search = "",
  isSearch = false,
  bookOrientation,
  hasFavoriteButton = true,
}: Props) => {
  const { title } = book;
  const isVertical = bookOrientation === "vertical";
  return (
    <BookWrapper
      isSearch={!!search || isSearch}
      bookOrientation={bookOrientation}
    >
      <div
        className={clsx(
          "relative rounded-lg overflow-hidden",
          isSearch ? "w-16 h-20" : "w-28 h-40"
        )}
      >
        {book.mainImage?.length && (
          <Link href={`/book/${book.titleIdentificator}`}>
            <Image
              fill
              src={book.mainImage[0].url}
              alt={book.mainImage[0].alternativeText}
              loading="lazy"
              sizes="(max-width: 768px) 100vw, 66vw"
              className="animate-appear-1"
              quality={50}
            />
          </Link>
        )}
      </div>
      <div className="flex-1 flex flex-col gap-2">
        <div className="flex-1">
          <Link href={`/book/${book.titleIdentificator}`}>
            <BookTitle searchText={search} initialText={title} />
          </Link>
        </div>
        <div className="flex flex-col gap-2">
          {book.authors[0] && isVertical && (
            <Link
              className="text-[#666666] text-xs"
              href={`/book/${book.titleIdentificator}`}
            >
              {book.authors[0].name}
            </Link>
          )}
          {book.price && isVertical && (
            <Link
              className="font-medium"
              href={`/book/${book.titleIdentificator}`}
            >
              {book.price} {book.currency}
            </Link>
          )}
          {hasFavoriteButton && !isSearch && <FavoriteButton book={book} />}
        </div>
      </div>
    </BookWrapper>
  );
};

interface IBookWrapper {
  children: ReactNode;
  isSearch?: boolean;
  bookOrientation?: "vertical" | "horizontal";
}

const BookWrapper: FC<IBookWrapper> = ({
  children,
  isSearch,
  bookOrientation,
}) => {
  return (
    <div
      className={clsx(
        "flex h-full",
        isSearch
          ? "items-start gap-8"
          : bookOrientation === "vertical"
          ? "flex-col gap-3 max-w-36"
          : "flex-col sm:flex-row sm:items-start sm:gap-8"
      )}
    >
      {children}
    </div>
  );
};

const Skeleton: FC<Omit<IBookWrapper, "children">> = ({ isSearch }) => {
  return (
    <BookWrapper isSearch={isSearch}>
      <div
        className={clsx(
          "bg-primary relative rounded-lg overflow-hidden mb-2 opacity-10 animate-pulse",
          isSearch ? "w-16 h-20" : "w-28 h-40"
        )}
      ></div>
      <div className="animate animate-pulse bg-slate-300 w-40 rounded-lg h-5"></div>
    </BookWrapper>
  );
};

Book.Skeleton = Skeleton;
export { Book };
