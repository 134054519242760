"use client";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Controller, Navigation } from "swiper/modules";
import { type FC } from "react";
import Image from "next/image";
import { SliderType } from "@/src/types";
import { SliderArrow } from "../common/SliderArrow";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

type Props = SliderType & {};

export const HeaderSlider: FC<Props> = ({ sliderItems }) => {
  return (
    <Swiper
      direction="horizontal"
      slidesPerView={1}
      spaceBetween={0}
      loop={true}
      modules={[Controller, Navigation]}
      allowTouchMove={false}
      className="relative py-6 h-full"
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
    >
      {sliderItems.map(({ alternativeText, url }) => {
        return (
          <SwiperSlide key={url}>
            <div className="relative flex mx-auto items-center justify-center w-64 h-full">
              <Image
                alt={alternativeText}
                src={url}
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                className="object-contain"
              />
            </div>
          </SwiperSlide>
        );
      })}
      {sliderItems.length > 1 && (
        <div className="absolute inset-0 z-10 flex items-stretch justify-between">
          <div className="flex flex-col justify-center items-end">
            <button className="swiper-button-prev group">
              <ChevronLeftIcon
                width={48}
                height={48}
                className="text-[#d9d9d9] opacity-100 transition-all duration-500 lg:group-hover:opacity-25"
              />
            </button>
          </div>
          <div className="flex flex-col justify-center items-end lg:group">
            <button className="swiper-button-next group">
              <ChevronRightIcon
                width={48}
                height={48}
                className="text-[#d9d9d9] opacity-100 transition-all duration-500 lg:group-hover:opacity-25"
              />
            </button>
          </div>
        </div>
      )}
    </Swiper>
  );
};
