import { type FC } from "react";
import Image from "next/image";
import { IStory } from "@/src/lib/story/types";
import { StoryIndicator } from "../common";
import clsx from "clsx";

type Props = {
  story: IStory;
  lng: string;
  bookView?: boolean;
  hasBlackLayer?: boolean;
};

export const StoryPageHeader: FC<Props> = ({
  story,
  lng,
  bookView = false,
  hasBlackLayer = false,
}) => {
  return (
    <div style={{ backgroundColor: story.backgroundColor }}>
      {story.mainPhoto && (
        <div className="relative w-full sb-height">
          {hasBlackLayer && <div className="absolute inset-0 z-10 " />}
          <div
            className={clsx(
              "absolute inset-0 pt-6 z-10 pl-6 text-3xl font-semibold ",
              hasBlackLayer
                ? "text-white bg-black bg-opacity-20 hover:bg-transparent transition-all duration-500"
                : ""
            )}
          >
            {story.title}
          </div>
          {bookView && (
            <div className="absolute top-0 left-0 bottom-0 flex items-center justify-end pl-5 z-20">
              <StoryIndicator lng={lng} />
            </div>
          )}
          <Image
            src={story.mainPhoto.url}
            alt={story.mainPhoto.alternativeText}
            fill
            style={{ objectFit: "cover" }}
            priority={true}
            loading="eager"
            quality={100}
            sizes="(max-width: 768px) 100vw, 66vw"
          />
        </div>
      )}
      {!bookView && story.subtitle && (
        <div
          className="p-6"
          dangerouslySetInnerHTML={{ __html: story.subtitle }}
          style={{ color: story.textColor }}
        ></div>
      )}
    </div>
  );
};
