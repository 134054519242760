"use client";
import { IMenuItem } from "@/src/lib";
import { extractPath } from "@/src/utils/functions";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";
import type { FC } from "react";

interface Props {
  menuItem: IMenuItem;
  lng: string;
}

export const DesktopMenuItem: FC<Props> = ({ menuItem, lng }) => {
  const pathname = usePathname();

  const isMenuItemActive = (i: string): boolean => {
    const { path } = extractPath(pathname);
    // case if home page
    if (path === "" && i === "/") return true;
    // case other pages
    if (i === path) return true;
    else return false;
  };
  return (
    <Link
      key={menuItem.id}
      href={
        menuItem.slug !== "pocetna"
          ? `/${lng}/${menuItem.slug || ""}`
          : `/${lng}`
      }
      className={clsx(
        "inline-flex items-center px-1 pt-1 font-medium",
        isMenuItemActive(`/${menuItem.slug === "pocetna" ? "" : menuItem.slug}`)
          ? "text-primary"
          : "text-secondary transition-all duration-150 hover:text-primary"
      )}
    >
      {menuItem.page}
    </Link>
  );
};
