"use client";
import {
  Bars3Icon,
  HeartIcon as FavoritesIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";

import Logo from "@/src/assets/logo.png";
import Image from "next/image";
import { ISearch, IMenuItem } from "@/src/lib";
import Search from "../Search/NavbarSearch/Search";
import { LanguageSwitch } from "../LanguageSwitch";
import { DesktopMenu } from "./Desktop";
import { MobileMenu, MobileMenuItems } from "./Mobile";
import { useState } from "react";
import clsx from "clsx";

interface Props {
  menuItems: IMenuItem[];
  lng: string;
  searchWords: ISearch;
}

export const Navbar: React.FC<Props> = ({ menuItems, lng, searchWords }) => {
  const [open, setOpen] = useState(false);
  return (
    <nav className="">
      <div className="bg-white shadow fixed top-0 right-0 left-0 z-50 pt-6">
        <div className="h-6 flex justify-between items-center px-4 sm:px-6 lg:px-8 max-w-[1920px]l mx-auto">
          <Link href={`/${lng}`}>
            <Image
              width={142}
              unoptimized
              height={16}
              loading="eager"
              priority
              src={Logo}
              alt="srpske knjige"
            />
          </Link>
          <div className="flex gap-2 items-center relative">
            {/* <SearchIcon width={28} height={28} /> */}
            <Search lng={lng} words={searchWords} />
            <Link href={`/${lng}/omiljene`}>
              <span className="sr-only">search</span>
              <FavoritesIcon color="#F4AD22" width={28} height={28} />
            </Link>
            <div className="hidden sm:flex">
              <LanguageSwitch lng={lng} />
            </div>
          </div>
        </div>
        <DesktopMenu lng={lng} menuItems={menuItems} />
        <div className="left-0 flex items-center sm:hidden h-16 px-4">
          <button
            onClick={() => setOpen((prev) => !prev)}
            className="relative inline-flex items-center justify-center rounded-md text-black"
          >
            <span className="absolute -inset-0.5" />
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {/* Mobile nav */}
      </div>
      <div
        className={clsx(
          "transition-all duration-700 overflow-hidden sm:hidden absolute top-0 right-0 left-0 bottom-0 z-[999999] bg-primary",
          open ? "w-full" : "w-0"
        )}
      >
        <div className="px-4 pt-12 h-full flex flex-col gap-20">
          <div className="relative flex h-16 justify-between">
            <button
              onClick={() => setOpen(false)}
              className="relative inline-flex items-center justify-center rounded-md text-white"
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Close main menu</span>
              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <MobileMenuItems setOpen={setOpen} menuItems={menuItems} lng={lng} />
        </div>
      </div>
    </nav>
  );
};
