"use client";
import { RelatedBooks as RelatedBooksType } from "@/src/types";
import { useRef, type FC } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import { Book } from "../book";

type Props = RelatedBooksType & {
  lng: string;
};

export const RelatedBooks: FC<Props> = ({ label, books, lng }) => {
  const paginationRef = useRef<HTMLDivElement | null>(null);
  return (
    <div className="py-6 px-6">
      {label && <div className="mb-3">{label}</div>}
      <Swiper
        loop
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 50,
          },
        }}
        pagination={{
          el: ".pagination-custom",
          type: "bullets",
          clickable: true,
          bulletClass: "category-pagination-bullet",
          bulletActiveClass: "category-pagination-bullet-active",
        }}
        modules={[Pagination]}
      >
        {books?.map((book) => {
          return (
            <SwiperSlide key={book.id}>
              <Link
                className="h-full flex-1 flex"
                href={`/book/${book.titleIdentificator}`}
              >
                <Book book={book} bookOrientation={"vertical"} lng={lng} />
              </Link>
            </SwiperSlide>
          );
        })}
        <div className="w-full flex py-3 justify-center">
          <div
            ref={paginationRef}
            className="max-w-max flex flex-wrap gap-3 pagination-custom"
          />
        </div>
      </Swiper>
    </div>
  );
};
