"use client";
import { useState, type FC } from "react";
import { FavoriteButton } from "../book";
import { IBook } from "@/src/lib";
import { Form } from "../contact";

type Props = {
  lng: string;
  book?: IBook;
};

export const StickyCallToAction: FC<Props> = ({ lng, book }) => {
  const [modal, setModal] = useState(false);
  if (!!book) {
    return (
      <div className="flex items-stretch w-full sticky bottom-0 z-10">
        <div className="flex-1 py-4 font-bold text-lg flex items-center justify-center gap-4 bg-white">
          <FavoriteButton book={book} />
          <div className="text-secondary">
            {book.price} {book.currency}
          </div>
        </div>
        <button
          onClick={() => setModal(true)}
          className="flex-1 bg-primary text-white font-bold text-lg flex items-center justify-center"
        >
          {lng === "sr-Cyrl" ? "Контакт" : "Kontakt"}
        </button>
        <Form
          bookImage={book.mainImage?.[0]}
          onClose={() => setModal(false)}
          title={book.title}
          buttonText={lng === "sr-Cyrl" ? "Пошаљи" : "Pošalji"}
          label={{
            email: lng === "sr-Cyrl" ? "имејл адреса" : "email",
            firstName: lng === "sr-Cyrl" ? "име" : "ime",
            lastName: lng === "sr-Cyrl" ? "презиме" : "prezime",
            message: lng === "sr-Cyrl" ? "порука" : "poruka",
          }}
          open={modal}
        />
      </div>
    );
  } else return null;
};
