"use client";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { MobileMenuItems } from ".";
import { useState } from "react";
import { IMenuItem } from "@/src/lib";

interface Props {
  menuItems: IMenuItem[];
  lng: string;
}

export const MobileMenu: React.FC<Props> = ({ menuItems, lng }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* Mobile menu button */}
      <div className="left-0 flex items-center sm:hidden h-16 px-4">
        <button
          onClick={() => setOpen((prev) => !prev)}
          className="relative inline-flex items-center justify-center rounded-md text-black"
        >
          <span className="absolute -inset-0.5" />
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      {/* Sidebar */}
      <div
        className={clsx(
          "transition-all duration-700 overflow-hidden sm:hidden absolute top-0 right-0 left-0 bottom-0 z-[999999] bg-primary",
          open ? "w-full" : "w-0"
        )}
      >
        <div className="px-4 pt-12 h-full flex flex-col gap-20">
          <div className="relative flex h-16 justify-between">
            <button
              onClick={() => setOpen(false)}
              className="relative inline-flex items-center justify-center rounded-md text-white"
            >
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Close main menu</span>
              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <MobileMenuItems setOpen={setOpen} menuItems={menuItems} lng={lng} />
        </div>
      </div>
    </>
  );
};
