"use client";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";

import {
  MagnifyingGlassIcon as SearchIcon,
  XMarkIcon as CloseIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useOnClickOutside } from "usehooks-ts";
import { ISearch } from "@/src/lib";
import { IBook } from "@/src/lib";
import { Book } from "@/src/components/book";

interface Props {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  words: ISearch;
  books: IBook[] | undefined;
  isLoading: boolean;
  lng: string;
}

export const MobileSearch: FC<Props> = ({
  words,
  setSearchTerm,
  searchTerm,
  books,
  isLoading,
  lng,
}) => {
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const mobileSearchRef = useRef(null);

  const setFocusOnInput = (): void => {
    inputRef.current?.focus();
  };

  useOnClickOutside(mobileSearchRef, () => {
    setMobileSearchOpen(false);
    setSearchTerm("");
  });
  return (
    <div className="sm:hidden">
      <SearchIcon
        ref={mobileSearchRef}
        onClick={() => {
          setMobileSearchOpen(true);
          setFocusOnInput();
        }}
        width={28}
        height={28}
        className="text-secondary cursor-pointer"
      />
      <div
        className={clsx(
          "fixed top-0 right-0 z-20 overflow-hidden transition-all duration-500 h-full",
          mobileSearchOpen ? "w-full" : "w-0"
        )}
      >
        <div
          className="w-full flex justify-between bg-white pt-6 pb-1 px-4"
          ref={mobileSearchRef}
        >
          <div className={clsx("flex gap-2 border-b pb-1 px-1")}>
            <input
              ref={inputRef}
              type="text"
              placeholder={words.placeholder}
              className="block bg-white text-secondary placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none"
              value={searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
            />
            <SearchIcon width={28} height={28} className="text-secondary" />
          </div>
          <CloseIcon
            onClick={() => {
              setMobileSearchOpen(false);
              setSearchTerm("");
            }}
            width={28}
            height={28}
            className="text-secondary"
          />
        </div>
        {Boolean(searchTerm && books?.length) && (
          <div className="bg-white flex flex-col space-y-4 rounded-md z-20 py-10 mx-3 mb-10 px-6 shadow-xl">
            {books?.map((book) => {
              return (
                <Book
                  isSearch
                  key={book.id}
                  book={book}
                  lng={lng}
                  search={searchTerm}
                />
              );
            })}
          </div>
        )}
        {isLoading && (
          <div className="bg-white flex space-y-4 rounded-md z-20 top-6 left-0 right-0 py-10 mx-3 px-6 shadow-md">
            <Book.Skeleton isSearch />
          </div>
        )}
        {books?.length === 0 && searchTerm && (
          <div className="bg-white flex justify-center space-y-4 rounded-md z-20 py-10 mx-3 mb-10 px-6 shadow-xl">
            {words.noDataText}
          </div>
        )}
      </div>
    </div>
  );
};
