"use client";
import { useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { MagnifyingGlassIcon as SearchIcon } from "@heroicons/react/24/outline";
import { ISearch, useSearchBooks } from "@/src/lib";
import { MobileSearch } from ".";
import { Book } from "@/src/components/book";

interface Props {
  lng: string;
  words: ISearch;
}

const Search: React.FC<Props> = ({ lng, words }) => {
  const { placeholder } = words;

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebounceValue(searchTerm, 600);

  const { data: books, isLoading } = useSearchBooks({
    filter: { filters: { title: { $contains: debouncedValue } }, locale: lng },
    enabled: Boolean(debouncedValue),
  });

  return (
    <>
      <MobileSearch
        lng={lng}
        isLoading={isLoading}
        books={books}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        words={words}
      />
      <div className="hidden sm:block">
        <div className="flex gap-2 border-b pb-1 px-1">
          <input
            type="text"
            placeholder={placeholder}
            className="block text-secondary placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none"
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
          />
          <SearchIcon width={28} height={28} className="text-secondary" />
        </div>

        {Boolean(books?.length) && (
          <div className="absolute bg-quaternary flex flex-col space-y-4 rounded-md z-20 top-10 left-0 right-0 px-6 shadow-md py-5">
            {books?.map((book: any) => {
              return (
                <Book
                  isSearch
                  search={searchTerm}
                  key={book.id}
                  book={book}
                  lng={lng}
                />
              );
            })}
          </div>
        )}
        {isLoading ? (
          <div className="absolute bg-quaternary flex justify-center rounded-md z-20 top-10 left-0 right-0 px-6 shadow-md py-5">
            <div className="animate-spin relative rounded-full border-t-4 w-10 h-10 border-t-primary"></div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Search;
