import { Cms, CmsQuery, PagedResult } from "@/src/utils";
import { IAllBooksPageData, IBook, IStrapiBook } from "./types";
import { handleComponent } from "../handleComponent";

const RES_KEY = "/books";
const RES_KEY_ALL_BOOKS = "/all-books-page";

export const getBooks = async (
  query?: CmsQuery
): Promise<PagedResult<IBook>> => {
  const client = Cms.createClient();
  return await client.fetchContentEntries(RES_KEY, { ...query, populate: "*" });
};

export const getBook = async (query: CmsQuery): Promise<IBook> => {
  const client = Cms.createClient();

  const book: IStrapiBook = await client.fetchContentEntry(RES_KEY, {
    ...query,
    populate: "deep,5",
  });

  return {
    ...book,
    content: book?.content?.map((item) => {
      return handleComponent(item);
    }),
  };
};

export const getAllBooksPageData = async (
  query: CmsQuery
): Promise<IAllBooksPageData> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_ALL_BOOKS, {
    ...query,
    filters: {
      pageId: { $eq: "all-books" },
    },
    populate: "*",
  });
};
