"use client";

import { FC, ReactNode, useCallback, useEffect, useState } from "react";

interface Props {
  initialText: string;
  searchText: string;
}

export const BookTitle: FC<Props> = ({ initialText, searchText }) => {
  const [text, setText] = useState<ReactNode[] | string>(initialText);

  const highlightText = useCallback(() => {
    if (!searchText) {
      setText(initialText);
      return;
    }

    const regex = new RegExp(`(${searchText})`, "gi"); // "g" - all occurrences, "i" - case insensitive
    const parts = initialText.split(regex);

    const highlightedText = parts.map((part, index) =>
      regex.test(part) ? <strong key={index}>{part}</strong> : part
    );

    setText(highlightedText);
  }, [initialText, searchText]);

  useEffect(() => {
    highlightText();
  }, [highlightText]);

  return (
    <div className="text-md text-tertiary text-wrap hover:text-secondary transition-all duration-200">
      {text}
    </div>
  );
};
